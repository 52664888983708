let lastId = 0;

export const uniqueID = (prefix = 'id') => {
  lastId++;
  return `${prefix}${lastId}`;
};

export const getOffset = (el) => {
  const rect = el?.getBoundingClientRect();
  const special_top_marge = el
    ?.closest('.section_content')
    .classList.contains('special_top_marge')
    ? 180
    : 0;
  return {
    left: rect?.left + window.scrollX,
    top: rect?.top + window.scrollY - special_top_marge,
  };
};

export const getReOrderedCarouselSlides = (slides) => {
  //make just double for inifinite carousel, set firstslide to 3rd element
  let _slides = [];
  if (slides.length > 0) {
    slides.push(...slides);
    const first_arr = slides.splice(-2);
    _slides = [...first_arr, ...slides];
  }
  return _slides;
};

export const getNumberIntegerFormat = (number, locale) => {
  let newNumber = number;
  if (locale === 'fr' && newNumber.indexOf('.') > 0) {
    newNumber = newNumber.replace('.', '');
  }
  if (locale === 'en' && newNumber.indexOf(',') > 0) {
    newNumber = newNumber.replace(',', '');
  }
  newNumber = newNumber.replace(',', '.');
  newNumber = newNumber.replace(/ /g, '');
  return parseFloat(newNumber);
};

export function getListOfYears() {
  const year = new Date().getFullYear();
  return Array.from(new Array(20), (val, index) => {
    return { id: index, label: '' + (year - index) };
  });
}

export function youtube_parser(url) {
  let regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let regExp2 =
    /^.*((youtube.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = url?.match(url.includes('youtu.be') ? regExp : regExp2);
  return match && match[7]?.length == 11 ? match[7] : false;
}

export function getPieChartConfigData() {
  return {
    chart: {
      renderTo: 'container',
      type: 'pie',
      backgroundColor: 'transparent',
    },
    credits: false,
    title: {
      text: null,
      style: {
        display: 'none',
      },
    },
    yAxis: {
      labels: {
        format: '{value}%',
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
      },
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
      },
    },
    series: [
      {
        name: '',
        data: [],
        size: '70%',
        innerSize: '70%',
        showInLegend: false,
        dataLabels: {
          enabled: true,
          //borderColor: 'black',
          //borderWidth: 1,
          overflow: 'none',
          connectorShape: 'straight',
          connectorWidth: 2,
          //"distance": 20,
          connectorPadding: 0,
          crop: false,
          reserveSpace: false,
          minSize: 60,
          formatter: function () {
            return (
              '<b style="text-align:left;font-size: 20px;">' +
              this.y +
              '% </b> <br/><b style="font-weight: normal; font-size: 12px;">' +
              this.point.name +
              '</b>'
            );
          },
          style: {
            color: '#2E2E2E',
            wordWrap: 'break-word',
            //width: '100px',
            textOutline: 'none',
          },
        },
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 320,
          },
          chartOptions: {
            series: [
              {
                size: '40%',
                formatter: function () {
                  return (
                    '<b style="text-align:left;font-size: 16px;">' +
                    this.y +
                    '% </b> <br/><b style="font-weight: normal; font-size: 10px;">' +
                    this.point.name +
                    '</b>'
                  );
                },
                dataLabels: {
                  distance: 10,
                },
              },
            ],
          },
        },
      ],
    },
  };
}

export function getLineChartConfigData(config) {
  const series = [];
  config.series_data.map((serie, index) => {
    const new_serie = {
      name: serie.name,
      data: serie.data,
      color: serie.color,
      valueSuffix: serie.valueSuffix,
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 7.5,
        fillColor: serie.marker_fillColor,
        lineColor: '#707070',
        lineWidth: 1,
      },
    };
    series.push(new_serie);
  });

  return {
    chart: {
      zoomType: 'xy',
      type: config.type,
      backgroundColor: 'transparent',
      plotBorderColor: '#bbb',
      plotBorderWidth: 1,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      gridLineDashStyle: 'dot',
      gridLineWidth: 1,
      gridLineColor: '#bbb',
      categories: config.xAxis_data,
      tickmarkPlacement: 'on',
      title: {
        enabled: true,
        margin: 15,
        text: config.xAxis_title,
        style: {
          color: '#2c4965',
        },
      },
      labels: {
        style: {
          color: '#2c4965',
        },
      },
    },
    yAxis: {
      min: config.yAxis_min || 0,
      gridLineColor: '#bbb',
      gridLineDashStyle: 'dot',
      labels: {
        format: config.yAxis_format,
        style: {
          color: '#2c4965',
        },
      },
      title: {
        enabled: true,
        margin: 20,
        text: config.yAxis_title,
        style: {
          color: '#2c4965',
        },
      },
    },
    series: series,
    legend: {
      itemStyle: {
        color: '#2c4965',
      },
    },
    tooltip: {
      pointFormat: config.tooltip_format,
      valueDecimals: 2,
      split: false,
      style: {
        color: '#2c4965',
      },
    },
  };
}

export function getLineColumnChartConfigData(config) {
  const yAxis_arr = [];
  config.yAxis_data.map((yAxis_single, index) => {
    const new_yAxis_single = {
      gridLineColor: '#bbb',
      gridLineDashStyle: 'dot',
      min: yAxis_single.min || 0,
      max: yAxis_single.max || null,
      labels: {
        format: yAxis_single.format,
        style: {
          color: '#2c4965',
        },
      },
      title: {
        enabled: true,
        margin: 20, //index === config.yAxis_data.length - 1 ? 20 : 20,
        text: yAxis_single.title,
        style: {
          color: '#2c4965',
        },
      },
      opposite: index === config.yAxis_data.length - 1 ? true : false,
    };
    yAxis_arr.push(new_yAxis_single);
  });

  return {
    chart: {
      zoomType: 'xy',
      backgroundColor: 'transparent',
      plotBorderColor: '#bbb',
      plotBorderWidth: 1,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      gridLineDashStyle: 'dot',
      gridLineWidth: 1,
      gridLineColor: '#bbb',
      categories: config.xAxis_data,
      crosshair: true,
      tickmarkPlacement: 'on',
      title: {
        enabled: true,
        margin: 15,
        text: config.xAxis_title,
        style: {
          color: '#2c4965',
        },
      },
      labels: {
        style: {
          color: '#2c4965',
        },
      },
    },
    yAxis: yAxis_arr,
    series: config.series_data,
    legend: {
      layout: 'vertical',
      itemMarginBottom: 6,
      itemStyle: {
        color: '#2c4965',
      },
    },
    tooltip: {
      shared: true,
      style: {
        color: '#2c4965',
      },
    },
  };
}

export function getMultipleLineChartConfigData(config) {
  const series_arr = [];
  config.series_data.map((serie, index) => {
    const new_serie = {
      name: serie.name,
      data: serie.data,
      color: serie.color,
      valueSuffix: serie.valueSuffix,
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 7.5,
        fillColor: serie.fillColor,
        lineColor: '#707070',
        lineWidth: 1,
      },
    };
    series_arr.push(new_serie);
  });

  const yAxis_arr = [];
  config.yAxis_data.map((yAxis_single, index) => {
    const new_yAxis_single = {
      gridLineColor: '#bbb',
      gridLineDashStyle: 'dot',
      min: 0,
      labels: {
        format: '{value} MT',
        style: {
          color: '#2c4965',
        },
      },
      title: {
        enabled: true,
        margin: 20, //index === config.yAxis_data.length - 1 ? 20 : 20,
        text: yAxis_single.title,
        style: {
          color: '#2c4965',
        },
      },
    };
    yAxis_arr.push(new_yAxis_single);
  });

  return {
    chart: {
      zoomType: 'xy',
      type: config.type,
      backgroundColor: 'transparent',
      plotBorderColor: '#bbb',
      plotBorderWidth: 1,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      gridLineDashStyle: 'dot',
      gridLineWidth: 1,
      gridLineColor: '#bbb',
      categories: config.xAxis_data,
      tickmarkPlacement: 'on',
      title: {
        enabled: true,
        margin: 15,
        text: config.yAxis_title,
        style: {
          color: '#2c4965',
        },
      },
      labels: {
        style: {
          color: '#2c4965',
        },
      },
    },
    yAxis: yAxis_arr,
    series: series_arr,
    legend: {
      layout: 'vertical',
      itemMarginBottom: 6,
      itemStyle: {
        color: '#2c4965',
      },
    },
    tooltip: {
      pointFormat: config.tooltip_format,
      valueDecimals: 2,
      split: false,
      style: {
        color: '#2c4965',
      },
    },
  };
}

export const decodeToHtml = (string) => {
  let returnVal = string;
  if (typeof document !== 'undefined') {
    let div = document?.createElement('div');
    div.innerHTML = string;
    returnVal =
      typeof div.textContent !== 'undefined' ? div.textContent : div.innerText;
  }

  return returnVal;
};

export const removeHtmlTag = (text) => {
  return decodeToHtml(text)?.replace(/<[^>]*>?/gm, '');
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'Ko', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
};

export const getBreadCrumb = (allMenu, currentUrl) => {
  let breadcrumbObj = {};
  const currentPath =
    '/' + currentUrl.split('/')[currentUrl.split('/').length - 1];
  const curr = allMenu.filter(
    (a) =>
      '/' +
        a?.node?.link?.url.split('/')[
          a?.node?.link?.url.split('/').length - 1
        ] ===
      currentPath
  );
  const parent = allMenu.filter(
    (a) =>
      a?.node?.drupal_id ===
      curr[0]?.node?.drupal_parent_menu_item?.split('menu_link_content:')[1]
  );
  const grandParent = allMenu.filter(
    (a) =>
      a?.node?.drupal_id ===
      parent[0]?.node?.drupal_parent_menu_item?.split('menu_link_content:')[1]
  );
  const gGrandParent = allMenu.filter(
    (a) =>
      a?.node?.drupal_id ===
      grandParent[0]?.node?.drupal_parent_menu_item?.split(
        'menu_link_content:'
      )[1]
  );

  if (curr.length > 0) {
    Object.assign(breadcrumbObj, {
      current: {
        name: curr[0]?.node?.title,
        link: curr[0]?.node?.link?.url,
      },
    });
  }

  if (parent.length > 0) {
    Object.assign(breadcrumbObj, {
      parent: {
        name: parent[0]?.node?.title,
        link:
          parent[0]?.node?.link?.url === '' ? '' : parent[0]?.node?.link?.url,
      },
    });
  }

  if (grandParent.length > 0) {
    Object.assign(breadcrumbObj, {
      grandParent: {
        name: grandParent[0]?.node?.title,
        link:
          grandParent[0]?.node?.link?.url === ''
            ? ''
            : grandParent[0]?.node?.link?.url,
      },
    });
  }

  if (gGrandParent.length > 0) {
    Object.assign(breadcrumbObj, {
      gGrandParent: {
        name: gGrandParent[0]?.node?.title,
        link:
          gGrandParent[0]?.node?.link?.url === ''
            ? ''
            : gGrandParent[0]?.node?.link?.url,
      },
    });
  }

  return breadcrumbObj;
};
