/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';

import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import DefaultShareImage from '../../assets/images/default-share-image.jpg';

function Seo({ description, lang, meta, title, url }) {
  const location = useLocation();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata.title;

  return (
    <Helmet
      title={title}
      titleTemplate={defaultTitle ? defaultTitle : null}
      meta={[
        {
          name: 'title',
          content: title,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: process.env.GATSBY_METADATA_SITE_URL + DefaultShareImage,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: '@GroupeColas',
        },
        {
          name: 'twitter:title',
          content: title || '',
        },
        {
          name: 'twitter:description',
          content: metaDescription || '',
        },
        {
          name: 'twitter:image',
          content: process.env.GATSBY_METADATA_SITE_URL + DefaultShareImage,
        },
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: 'fr',
  meta: [],
  description: '',
  author: '',
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
};

export default Seo;
