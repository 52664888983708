import React, { useState, useEffect } from 'react';

import Aos from 'aos';
import { graphql } from 'gatsby';
import { Waypoint } from 'react-waypoint';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import VideoMP4 from '../../../components/Video/VideoMP4';
import VideoYoutube from '../../../components/Video/VideoYoutube';
import useMedias from '../../../hooks/useMedias';
import Layout from '../../../Layout';
import { Button } from '../../../Molecules/Button/Button';
import TitleHistory from '../../../Molecules/TitleHistory/TitleHistory';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import { getBreadCrumb, youtube_parser } from '../../../Utils/Utils';

import './styles.scss';
let classNames = require('classnames');

const ColasHistory = ({ data }) => {
  const intl = useIntl();
  const { processUrl, getImage, getVideo } = useMedias();

  const historyData = data?.historyData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;
  const videosArray = data.allVideos.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, historyData.path?.alias);
  const [innerNav, setInnerNav] = useState([]);
  const [simulateNavClick, setSimulateNavClick] = useState(true);
  const metaTags =  historyData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
   metaTags.forEach((meta) => {
      if(meta.attributes.name === 'title'){
           metaTitle = meta.attributes.content;
      }
      if(meta.attributes.name === 'description'){
           metaDesc = meta.attributes.content;
      }
    });
  const stringToHTML = (text) => {
    return { __html: text };
  };

  const triggerClickNav = (itemNav) => {
    if (simulateNavClick) {
      document.body
        .querySelector('.scroll_page_nav [data-section= ' + itemNav + ']')
        .click();
    }
  };

  const doClickNav = () => {
    setSimulateNavClick(false);

    setTimeout(function () {
      setSimulateNavClick(true);
    }, 600);
  };

  useEffect(() => {
    let navList = [];
    historyData.relationships?.field_blocs?.forEach((block) => {
      block.__typename === 'block_content__block_histoire' &&
        navList.push({
          title: block.field_title?.processed,
          section: block.field_decade,
        });
    });
    setInnerNav(navList);
  }, []);

  // Animation
  useEffect(() => {
    Aos.init();

    if (typeof window === 'undefined' || !window.document) {
      return;
    }
    if (!document.body) return 0;

    setTimeout(function () {
      document.body
        .querySelector('.scroll_page_nav')
        .addEventListener('click', doClickNav, false);
    }, 200);
  }, []);

  return (
    //
    <Layout>
      <Seo
        title={historyData?.field_metatag?.title ? historyData?.field_metatag?.title : metaTitle}
        description={historyData?.field_metatag?.description ? historyData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'colas_history_page')}>
        {innerNav.length > 0 && <ScrollPageNav data={innerNav} />}
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              //grandParentPage: { title: 'Acceuile', url: '/' },
              gparent: {
                title: breadCrumb?.grandParent?.name,
                url: breadCrumb?.grandParent?.link,
              },
              parentPage: {
                title: breadCrumb?.parent?.name,
                url: breadCrumb?.parent?.link,
              },
              currentPage: {
                title: breadCrumb?.current?.name,
                url: breadCrumb?.current?.link,
              },
              locale: historyData.langcode,
            }}
          />
          <TitlePage color="color_dark_bleu" title={historyData.title} />
        </div>

        {historyData.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__block_video':
              return (
                <div className="wrapper_page" key={i}>
                  {block.field_link && (
                    <VideoYoutube
                      cover={processUrl(
                        getImage(
                          imagesArray,
                          block?.relationships?.field_image
                            ?.drupal_internal__mid
                        )?.uri?.url
                      )}
                      videoID={youtube_parser(block.field_link?.uri)}
                      videoTitle={historyData.title}
                      obj={{
                        height: '390',
                        width: '640',
                        playerVars: {
                          autoplay: 0,
                          controls: 0,
                          disablekb: 1,
                          rel: 0,
                          showinfo: 0,
                        },
                      }}
                    />
                  )}
                  {block?.relationships?.field_mp4 && (
                    <VideoMP4
                      cover={getImage(
                        imagesArray,
                        block?.relationships?.field_image?.drupal_internal__mid
                      )}
                      videoLink={
                        getVideo(
                          videosArray,
                          block?.relationships?.field_mp4?.drupal_internal__mid
                        )?.uri.url
                      }
                      title={historyData.title}
                    />
                  )}
                </div>
              );

            case 'block_content__block_histoire':
              return (
                <Waypoint
                  onEnter={() => triggerClickNav(block.field_decade)}
                  bottom="50%"
                  key={i}
                >
                  <section
                    className={classNames(
                      'section_content',
                      block.field_decade
                    )}
                    data-aos="fade-up"
                    data-aos-delay={200}
                    data-aos-easing="ease-in-sine"
                    data-aos-duration={600}
                  >
                    <div className="only_mobile_tablet wrapper_page">
                      <TitleHistory
                        label={intl.formatMessage({
                          id: 'history.decade.label',
                        })}
                        title={block.field_title?.processed}
                        type="line1"
                      />
                      <div className="image_year">
                        <img
                          src={processUrl(
                            getImage(
                              imagesArray,
                              block.relationships?.field_images_multiple[0]
                                ?.drupal_internal__mid
                            ).uri.url
                          )}
                          alt=""
                          style={{ objectFit: 'cover' }}
                        />
                      </div>
                      <div className="text_section">
                        <div
                          className="list_container"
                          dangerouslySetInnerHTML={stringToHTML(
                            block.body?.processed
                          )}
                        ></div>
                      </div>
                      <div className="button_discover">
                        <Button
                          primary={true}
                          label={block.field_lien?.title}
                          link={block.field_lien?.url}
                          aria-label={
                            block.field_lien?.title +
                            ' - ' +
                            intl.formatMessage({ id: 'history.decade.label' }) +
                            ' ' +
                            block.field_title?.processed
                          }
                        />
                      </div>
                    </div>
                    <div className="only_desktop wrapper_page">
                      <div className="middle_desktop">
                        <div className="text_container">
                          <div className="container_title">
                            <TitleHistory
                              label={intl.formatMessage({
                                id: 'history.decade.label',
                              })}
                              title={block.field_title?.processed}
                              type="line1"
                            />
                          </div>
                          <div className="text_section">
                            <div
                              className="list_container"
                              dangerouslySetInnerHTML={stringToHTML(
                                block.body?.processed
                              )}
                            ></div>

                            {/*<div className="button_discover">
                              <Button
                                primary={true}
                                label={block.field_lien?.title}
                                link={block.field_lien?.url}
                              />
                              </div>*/}
                          </div>
                        </div>
                        <div className="image_year">
                          {block.relationships?.field_images_multiple?.map(
                            (image, j) => (
                              <div key={j} className="visuel">
                                <img
                                  src={processUrl(
                                    getImage(
                                      imagesArray,
                                      image.drupal_internal__mid
                                    ).uri.url
                                  )}
                                  alt=""
                                  style={{ objectFit: 'cover' }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </Waypoint>
              );
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query historyTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $videoID: [Int!]
  ) {
    historyData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_histoire {
                  field_decade
                  field_title {
                    processed
                  }
                  relationships {
                    field_images_multiple {
                      drupal_internal__mid
                    }
                  }
                  body {
                    processed
                  }
                  field_lien {
                    title
                    url
                  }
                }
                ... on block_content__block_video {
                  field_link {
                    uri
                  }
                }
              }
            }
          }
          path {
            alias
            langcode
          }
          title
          langcode
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }
    allVideos: allMediaVideo(
      filter: { langcode: { eq: "fr" }, drupal_internal__mid: { in: $videoID } }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_video_file {
              uri {
                url
              }
            }
          }
          langcode
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default ColasHistory;
