import * as React from 'react';

import PropTypes from 'prop-types';

import { HorizontalYellowLineShape3 } from '../../Atoms/Icons/Icons';

import './TitleHistory.scss';

function TitleHistory({ label, title }) {
  return (
    <div className="title_history">
      <div className="icon">
        <HorizontalYellowLineShape3 />
      </div>
      <h2>
        {/* <span className="small">{label}</span> */}
        <span className="big">{title}</span>
      </h2>
    </div>
  );
}

TitleHistory.defaultProps = {
  title: 'Title',
  label: 'arrow'
};

TitleHistory.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string
};

export default TitleHistory;
